module.exports.permissions = {
  free: {
    listing_types: ["residential"],
    views: ["table", "map", "oppertunity_feed"],
    listing_actions: ["save_hide_user", "save_search", "new_listing_notification"],
    sidebar_sections: ["ai_pricing", "ai_market_check", "time_on_market", "contact_details"],
    locations: ["state", "qld", "brisbane"],
    filters: ["postal_code", "suburb", "property_type", "area", "configuration"],
    property_data: ["size", "listing_source", "google_search", "dimensions"],
    stocklist: ["user_hide_listing"],
  },
  starter: {
    listing_types: ["residential", "commercial"],
    views: ["table", "map", "oppertunity_feed"],
    listing_actions: ["save_hide_user", "save_search", "new_listing_notification"],
    sidebar_sections: ["ai_pricing", "ai_market_check", "time_on_market", "council_flags", "contact_details", "council_information"],
    locations: ["state", "qld", "brisbane"],
    filters: ["postal_code", "suburb", "property_type", "property_type_other", "area", "configuration", "state", "council", "postal_code", "suburb"],
    property_data: ["size", "listing_source", "google_search", "council_information", "dimensions"],
    stocklist: ["user_hide_listing"],
  },
  professional: {
    listing_types: ["residential", "commercial"],
    views: ["table", "map", "oppertunity_feed"],
    listing_actions: ["save_hide_user", "save_search", "new_listing_notification"],
    sidebar_sections: ["ai_pricing", "ai_market_check", "time_on_market", "council_flags", "contact_details", "previous_sales_data", "council_information"],
    locations: ["state", "qld", "brisbane"],
    filters: [
      "address",
      "street",
      "suburb",
      "state",
      "postal_code",
      "council",
      "status",
      "property_type",
      "property_type_other",
      "price",
      "city_plan_",
      "length",
      "area",
      "fall",
      "hide",
      "updated",
      "additional_type",
      "property_data",
      "frontages",
      "configuration",
      "exclusion_filters",
      // property filters
      "lot_format",
      "property_t",
      "tenure_des",
      "body_corporate",
    ],
    property_data: [
      "size",
      "zone",
      "slope",
      "frontage",
      "listing_source",
      "google_search",
      "council_information",
      "sewer",
      "water",
      "power",
      "stormwater",
      "development_approvals",
      "dimensions",
      "electrical",
    ],
    stocklist: ["user_hide_listing"],
  },
  agency: {
    listing_types: ["residential", "commercial"],
    views: ["table", "map"],
    listing_actions: ["save_hide_user", "save_search", "new_listing_notification"],
    sidebar_sections: ["ai_pricing", "ai_market_check", "time_on_market", "council_flags", "contact_details", "previous_sales_data", "council_information"],
    locations: ["state", "qld", "brisbane"],
    filters: [
      "address",
      "street",
      "suburb",
      "state",
      "postal_code",
      "council",
      "status",
      "property_type",
      "property_type_other",
      "price",
      "city_plan_",
      "length",
      "area",
      "fall",
      "hide",
      "updated",
      "additional_type",
      "property_data",
      "frontages",
      "configuration",
      "exclusion_filters",
      // property filters
      "lot_format",
      "property_t",
      "tenure_des",
      "body_corporate",
    ],
    property_data: [
      "size",
      "zone",
      "slope",
      "frontage",
      "listing_source",
      "google_search",
      "council_information",
      "sewer",
      "water",
      "power",
      "stormwater",
      "development_approvals",
      "dimensions",
      "electrical",
    ],
    stocklist: ["user_hide_listing"],
  },
  // admin profile
  admin: {
    listing_types: ["residential", "commercial"],
    views: ["table", "map", "oppertunity_feed", "listing_copy_writing"],
    listing_actions: ["save_hide_user", "save_search", "new_listing_notification"],
    sidebar_sections: ["ai_pricing", "ai_market_check", "time_on_market", "council_flags", "contact_details", "previous_sales_data", "council_information"],
    locations: ["state", "qld", "brisbane"],
    filters: [
      "address",
      "street",
      "suburb",
      "state",
      "postal_code",
      "council",
      "status",
      "property_type",
      "property_type_other",
      "price",
      "city_plan_",
      "length",
      "area",
      "fall",
      "hide",
      "updated",
      "additional_type",
      "property_data",
      "frontages",
      "configuration",
      "exclusion_filters",
      // property filters
      "lot_format",
      "property_t",
      "tenure_des",
      "body_corporate",
    ],
    property_data: [
      "size",
      "zone",
      "slope",
      "frontage",
      "listing_source",
      "google_search",
      "council_information",
      "sewer",
      "water",
      "power",
      "stormwater",
      "development_approvals",
      "dimensions",
      "electrical",
    ],
    stocklist: ["add_listing", "edit_listing", "hide_listing", "remove_listing", "set_permissions"],
  },
};
