/* eslint-disable quotes */
export default [
  // Invoice
  {
    path: "/admn/invoice/list",
    name: "invoice-list",
    component: () => import("@/views/invoice/invoice-list/InvoiceList.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admn/invoice/preview/:id",
    name: "invoice-preview",
    component: () => import("@/views/invoice/invoice-preview/InvoicePreview.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admn/invoice/add/",
    name: "invoice-add",
    component: () => import("@/views/invoice/invoice-add/InvoiceAdd.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admn/invoice/edit/:id",
    name: "invoice-edit",
    component: () => import("@/views/invoice/invoice-edit/InvoiceEdit.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
];
