export default [
  {
    path: "/admin/dashboard",
    name: "home",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/home/dashboard",
    name: "user-home",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
    meta:
    {
      resource: "User",
      action: "read",
    },

  },
  {
    path: "/teamMember/dashboard",
    name: "home",
    component: () => import("@/views/dashboard/analytics/Analytics.vue"),
    meta:
    {
      resource: "Team Member",
      action: "read",
    },

  },

];
