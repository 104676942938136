export default [
    {
        path: "/admin/map",
        name: "Map",
        component: () => import("@/views/map/Map.vue"),
        meta: {
            resource: "Admin",
            action: "read",
        },
    },
]