import Vue from 'vue'
// Vue.config.productionTip = false;
let API_ENDPOINT = "";
if (window.location.hostname == 'localhost') {
  Vue.config.productionTip = false;
  API_ENDPOINT = 'http://127.0.0.1:8011';
} else {
  Vue.config.productionTip = true;
  API_ENDPOINT = 'https://api.clients.remap.ai';
}

// const API_ENDPOINT = (Vue.config.productionTip) ? 'http://52.63.253.187:8001' : 'http://127.0.0.1:3000';
console.log("JWT File" + API_ENDPOINT);

export default {
  // Endpoints

  loginEndpoint: `${API_ENDPOINT}/users/login`,
  registerEndpoint: `${API_ENDPOINT}/users/signup`,
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
