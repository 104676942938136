/* eslint-disable import/no-unresolved */
/* eslint-disable quotes */
export default [
  // *===============================================---*
  // *--------- Subscriptions ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/admin/subscriptions",
    name: "subscriptions",
    component: () => import("@/views/subscriptions/subscriptions-list/SubscriptionsList.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/subscriptions/view/:id",
    name: "subscriptions-view",
    component: () => import("@/views/subscriptions/subscriptions-view/SubscriptionsView.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/subscriptions/edit/:id",
    name: "subscriptions-edit",
    component: () => import("@/views/subscriptions/subscriptions-edit/SubscriptionsEdit.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },

  {
    path: "/admin/subscriptions/add",
    name: "subscriptions-add",
    component: () => import("@/views/subscriptions/subscriptions-add/AddSubscription.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
];
