/* eslint-disable import/no-extraneous-dependencies */
import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import axios from 'axios'
import Vuex from 'vuex'
import helpers from './helper/helper'
import config from './config'

//Loader
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
Vue.use(Vuesax, {
  // options here
})


//Helper Plugin;
const helperPlugin = {
  install() {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
}
Vue.use(helperPlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Vuex)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const BASE_URL = config.BASE_URL;

const API_BASE_USERS = BASE_URL + '/users'
const API_BASE_GROUPS = BASE_URL + '/groups'
const API_BASE_PLANS = BASE_URL + '/plans'
const API_BASE_SUBSCRIPTIONS = BASE_URL + '/subscriptions'
const API_BASE_LISTINGS = BASE_URL + '/listings'
const API_BASE_FEATURES = BASE_URL + '/features'
const API_BASE_AUTH = BASE_URL + '/auth'
const API_BASE_MAP = BASE_URL + '/map'

Vue.prototype.$API_URL_USER = API_BASE_USERS;
Vue.prototype.$API_URL_GROUPS = API_BASE_GROUPS;
Vue.prototype.$API_URL_PLANS = API_BASE_PLANS;
Vue.prototype.$API_URL_SUBSCRIPTIONS = API_BASE_SUBSCRIPTIONS;
Vue.prototype.$API_BASE_LISTINGS = API_BASE_LISTINGS;
Vue.prototype.$API_BASE_FEATURES = API_BASE_FEATURES;
Vue.prototype.$API_BASE_AUTH = API_BASE_AUTH;
Vue.prototype.$API_BASE_MAP = API_BASE_MAP;
Vue.prototype.$axios = axios;
Vue.prototype.$url = window.location.origin;


// Adding fontawsome libraries
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(fab)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//Leaflet Map
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

import { Icon } from 'leaflet';
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
