/* eslint-disable import/no-unresolved */
/* eslint-disable quotes */
export default [
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/admin/users",
    name: "users",
    component: () => import("@/views/users/users-list/UsersList.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/users/view/:id",
    name: "users-view",
    component: () => import("@/views/users/users-view/UsersView.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/users/edit/:id",
    name: "users-edit",
    component: () => import("@/views/users/users-edit/UsersEdit.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },

  {
    path: "/admin/users/add",
    name: "users-add",
    component: () => import("@/views/users/users-add/AddUser.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/groupPermission",
    name: "groupPermission",
    component: () => import("@/views/group-permission/GroupPermission.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  }
]
