/* eslint-disable import/no-unresolved */
/* eslint-disable quotes */
export default [
  // *===============================================---*
  // *--------- Plans ---- ---------------------------------------*
  // *===============================================---*
  {
    path: "/admin/plans",
    name: "plans",
    component: () => import("@/views/plans/plans-list/PlansList.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/plans/view/:id",
    name: "plans-view",
    component: () => import("@/views/plans/plans-view/PlansView.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/plans/edit/:id",
    name: "plans-edit",
    component: () => import("@/views/plans/plans-edit/PlansEdit.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },

  {
    path: "/admin/plans/add",
    name: "plans-add",
    component: () => import("@/views/plans/plans-add/AddPlan.vue"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
];
