const { permissions } = require("./permissions");

module.exports.permissions = {
  state: () => ({}),
  getters: {},
  mutations: {},
  actions: {
    get_permissions: async (state, getters) => {
      try {
        let user_data = localStorage.getItem("userData");

        if (!user_data) throw new Error("No user Data found - Kindly relogin to continue");
        user_data = JSON.parse(user_data);

        // case: user is an admin
        let user_groups = user_data.groups;
        // let admin_groups = user_groups.filter((g) => g.name && g.name.toLowerCase() == "admin");
        if (user_groups && user_groups.name && user_groups.name.toLowerCase() == "admin") {
          // console.log("Admin permissions: ", permissions["admin"]);
          return permissions["admin"];
        }

        // case; user is plan based
        let plan = user_data.planname.toLowerCase().replace(/\s/gi, "");
        if (!plan || !permissions[plan]) throw new Error("No permissions found for this plan");
        // console.log("plan: ", plan);
        // console.log("permissions: ", permissions[plan]);
        return permissions[plan];
      } catch (error) {
        console.log(error);
        return null;
      }
    },
  },
};
