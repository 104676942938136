import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'

const BASE_URL = (Vue.config.productionTip) ? 'http://52.63.253.187:8001' : 'http://127.0.0.1:8011';
const API_BASE_USERS = BASE_URL + '/users'
const API_BASE_GROUPS = BASE_URL + '/groups'

const fetchGroups = async () => {
    const res = await this.$axios.get(`${API_BASE_GROUPS}/fetchGroups`);
    let group = await res;
    return group;
}

const fetchUserData = () => {
    let data = localStorage.getItem("userData");
    let userData = JSON.parse(data);
    console.log(`User Data : ${userData.role}`);
    return userData;
}

const dateFormat = (value) => {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
    }
}

const dateFormatUnix = (value) => {
    if (value) {
        return moment.unix(value).format('YYYY-MM-DD')
    }
}


export default { fetchGroups, fetchUserData, dateFormat, dateFormatUnix };