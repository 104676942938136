import mock from '@/@fake-db/mock'
import { paginateArray, sortCompare } from '@/@fake-db/utils'

/* eslint-disable global-require */
const data = {
  listings: [
    {
      id: 2,
      address: 'Halsey Redmore',
      company: 'Skinder PVT LTD',
      zone: 'wed 7:00 pm',
      username: 'hredmore1',
      country: 'Albania',
      contact: '(472) 607-9137',
      size: '$45',
      price: 'team',
      date: 'pending',
      avatar: require('@/assets/images/avatars/10.png'),
    },
    {
      id: 3,
      address: 'Marjory Sicely',
      company: 'Oozz PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'msicely2',
      country: 'Russia',
      contact: '(321) 264-4599',
      size: '$46',
      price: 'enterprise',
      date: 'active',
      avatar: require('@/assets/images/avatars/1.png'),
    },
    {
      id: 4,
      address: 'Cyrill Risby',
      company: 'Oozz PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'crisby3',
      country: 'China',
      contact: '(923) 690-6806',
      size: '$47',
      price: 'team',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 5,
      address: 'Maggy Hurran',
      company: 'Aimbo PVT LTD',
      zone: 'Tue 6:30am',
      username: 'mhurran4',
      country: 'Pakistan',
      contact: '(669) 914-1078',
      size: '$48',
      price: 'enterprise',
      date: 'pending',
      avatar: require('@/assets/images/avatars/10.png'),
    },
    {
      id: 6,
      address: 'Silvain Halstead',
      company: 'Jaxbean PVT LTD',
      zone: 'wed 7:00 pm',
      username: 'shalstead5',
      country: 'China',
      contact: '(958) 973-3093',
      size: '$49',
      price: 'company',
      date: 'active',
      avatar: '',
    },
    {
      id: 7,
      address: 'Breena Gallemore',
      company: 'Jazzy PVT LTD',
      zone: 'Tue 6:30am',
      username: 'bgallemore6',
      country: 'Canada',
      contact: '(825) 977-8152',
      size: '$50',
      price: 'company',
      date: 'pending',
      avatar: '',
    },
    {
      id: 8,
      address: 'Kathryne Liger',
      company: 'Pixoboo PVT LTD',
      zone: 'wed 7:00 pm',
      username: 'kliger7',
      country: 'France',
      contact: '(187) 440-0934',
      size: '$40',
      price: 'enterprise',
      date: 'pending',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 9,
      address: 'Franz Scotfurth',
      company: 'Tekfly PVT LTD',
      zone: 'Tue 6:30am',
      username: 'fscotfurth8',
      country: 'China',
      contact: '(978) 146-5443',
      size: '$41',
      price: 'team',
      date: 'pending',
      avatar: require('@/assets/images/avatars/2.png'),
    },
    {
      id: 10,
      address: 'Jillene Bellany',
      company: 'Gigashots PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'jbellany9',
      country: 'Jamaica',
      contact: '(589) 284-6732',
      size: '$108',
      price: 'company',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 11,
      address: 'Jonah Wharlton',
      company: 'Eare PVT LTD',
      zone: 'Tue 6:30am',
      username: 'jwharltona',
      country: 'United States',
      contact: '(176) 532-6824',
      size: '$100',
      price: 'team',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/4.png'),
    },
    {
      id: 12,
      address: 'Seth Hallam',
      company: 'Yakitri PVT LTD',
      zone: 'Tue 6:30am',
      username: 'shallamb',
      country: 'Peru',
      contact: '(234) 464-0600',
      size: '$66',
      price: 'team',
      date: 'pending',
      avatar: require('@/assets/images/avatars/5.png'),
    },
    {
      id: 13,
      address: 'Yoko Pottie',
      company: 'Leenti PVT LTD',
      zone: 'Tue 6:30am',
      username: 'ypottiec',
      country: 'Philippines',
      contact: '(907) 284-5083',
      size: '$87',
      price: 'basic',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/7.png'),
    },
    {
      id: 14,
      address: 'Maximilianus Krause',
      company: 'Digitube PVT LTD',
      zone: 'wed 7:00 pm',
      username: 'mkraused',
      country: 'Democratic Republic of the Congo',
      contact: '(167) 135-7392',
      size: '$42',
      price: 'team',
      date: 'active',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 15,
      address: 'Zsazsa McCleverty',
      company: 'Kaymbo PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'zmcclevertye',
      country: 'France',
      contact: '(317) 409-6565',
      size: '$4587',
      price: 'enterprise',
      date: 'active',
      avatar: require('@/assets/images/avatars/2.png'),
    },
    {
      id: 16,
      address: 'Bentlee Emblin',
      company: 'Yambee PVT LTD',
      zone: 'wed 7:00 pm',
      username: 'bemblinf',
      country: 'Spain',
      contact: '(590) 606-1056',
      size: '$78',
      price: 'company',
      date: 'active',
      avatar: require('@/assets/images/avatars/6.png'),
    },
    {
      id: 17,
      address: 'Brockie Myles',
      company: 'Wikivu PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'bmylesg',
      country: 'Poland',
      contact: '(553) 225-9905',
      size: '$68',
      price: 'basic',
      date: 'active',
      avatar: '',
    },
    {
      id: 18,
      address: 'Bertha Biner',
      company: 'Twinte PVT LTD',
      zone: 'Fri 6:00am',
      username: 'bbinerh',
      country: 'Yemen',
      contact: '(901) 916-9287',
      size: '$74',
      price: 'team',
      date: 'active',
      avatar: require('@/assets/images/avatars/7.png'),
    },
    {
      id: 19,
      address: 'Travus Bruntjen',
      company: 'Cogidoo PVT LTD',
      zone: 'Sat 11:00am',
      username: 'tbruntjeni',
      country: 'France',
      contact: '(524) 586-6057',
      size: '$100',
      price: 'enterprise',
      date: 'active',
      avatar: '',
    },
    {
      id: 20,
      address: 'Wesley Burland',
      company: 'Bubblemix PVT LTD',
      zone: 'Fri 6:00am',
      username: 'wburlandj',
      country: 'Honduras',
      contact: '(569) 683-1292',
      size: '$87',
      price: 'team',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/6.png'),
    },
    {
      id: 21,
      address: 'Selina Kyle',
      company: 'Wayne Enterprises',
      zone: 'Sat 11:00am',
      username: 'catwomen1940',
      country: 'USA',
      contact: '(829) 537-0057',
      size: '$78',
      price: 'team',
      date: 'active',
      avatar: require('@/assets/images/avatars/1.png'),
    },
    {
      id: 22,
      address: 'Jameson Lyster',
      company: 'Quaxo PVT LTD',
      zone: 'Fri 6:00am',
      username: 'jlysterl',
      country: 'Ukraine',
      contact: '(593) 624-0222',
      size: '$87',
      price: 'company',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/8.png'),
    },
    {
      id: 23,
      address: 'Kare Skitterel',
      company: 'Ainyx PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'kskitterelm',
      country: 'Poland',
      contact: '(254) 845-4107',
      size: '$69',
      price: 'basic',
      date: 'pending',
      avatar: require('@/assets/images/avatars/3.png'),
    },
    {
      id: 24,
      address: 'Cleavland Hatherleigh',
      company: 'Flipopia PVT LTD',
      zone: 'Sat 11:00am',
      username: 'chatherleighn',
      country: 'Brazil',
      contact: '(700) 783-7498',
      size: '$96',
      price: 'team',
      date: 'pending',
      avatar: require('@/assets/images/avatars/2.png'),
    },
    {
      id: 25,
      address: 'Adeline Micco',
      company: 'Topicware PVT LTD',
      zone: 'Sat 11:00am',
      username: 'amiccoo',
      country: 'France',
      contact: '(227) 598-1841',
      size: '$85',
      price: 'enterprise',
      date: 'pending',
      avatar: '',
    },
    {
      id: 26,
      address: 'Hugh Hasson',
      company: 'Skinix PVT LTD',
      zone: 'Sat 11:00am',
      username: 'hhassonp',
      country: 'China',
      contact: '(582) 516-1324',
      size: '$58',
      price: 'basic',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/4.png'),
    },
    {
      id: 27,
      address: 'Germain Jacombs',
      company: 'Youopia PVT LTD',
      zone: 'Fri 6:00am',
      username: 'gjacombsq',
      country: 'Zambia',
      contact: '(137) 467-5393',
      size: '$78',
      price: 'enterprise',
      date: 'active',
      avatar: require('@/assets/images/avatars/10.png'),
    },
    {
      id: 28,
      address: 'Bree Kilday',
      company: 'Jetpulse PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'bkildayr',
      country: 'Portugal',
      contact: '(412) 476-0854',
      size: '$45',
      price: 'team',
      date: 'active',
      avatar: '',
    },
    {
      id: 29,
      address: 'Candice Pinyon',
      company: 'Kare PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'cpinyons',
      country: 'Sweden',
      contact: '(170) 683-1520',
      size: '$45',
      price: 'team',
      date: 'active',
      avatar: require('@/assets/images/avatars/7.png'),
    },
    {
      id: 30,
      address: 'Isabel Mallindine',
      company: 'Voomm PVT LTD',
      zone: 'Tue 6:30am',
      username: 'imallindinet',
      country: 'Slovenia',
      contact: '(332) 803-1983',
      size: '$45',
      price: 'team',
      date: 'pending',
      avatar: '',
    },
    {
      id: 31,
      address: 'Gwendolyn Meineken',
      company: 'Oyondu PVT LTD',
      zone: 'Sat 11:00am',
      username: 'gmeinekenu',
      country: 'Moldova',
      contact: '(551) 379-7460',
      size: '$45',
      price: 'basic',
      date: 'pending',
      avatar: require('@/assets/images/avatars/1.png'),
    },
    {
      id: 32,
      address: 'Rafaellle Snowball',
      company: 'Fivespan PVT LTD',
      zone: 'Fri 6:00am',
      username: 'rsnowballv',
      country: 'Philippines',
      contact: '(974) 829-0911',
      size: '$45',
      price: 'basic',
      date: 'pending',
      avatar: require('@/assets/images/avatars/5.png'),
    },
    {
      id: 33,
      address: 'Rochette Emer',
      company: 'Thoughtworks PVT LTD',
      zone: 'Sat 11:00am',
      username: 'remerw',
      country: 'North Korea',
      contact: '(841) 889-3339',
      size: '$45',
      price: 'basic',
      date: 'active',
      avatar: require('@/assets/images/avatars/8.png'),
    },
    {
      id: 34,
      address: 'Ophelie Fibbens',
      company: 'Jaxbean PVT LTD',
      zone: 'Tue 6:30am',
      username: 'ofibbensx',
      country: 'Indonesia',
      contact: '(764) 885-7351',
      size: '$45',
      price: 'company',
      date: 'active',
      avatar: require('@/assets/images/avatars/4.png'),
    },
    {
      id: 35,
      address: 'Stephen MacGilfoyle',
      company: 'Browseblab PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'smacgilfoyley',
      country: 'Japan',
      contact: '(350) 589-8520',
      size: '$45',
      price: 'company',
      date: 'pending',
      avatar: '',
    },
    {
      id: 36,
      address: 'Bradan Rosebotham',
      company: 'Agivu PVT LTD',
      zone: 'Tue 6:30am',
      username: 'brosebothamz',
      country: 'Belarus',
      contact: '(882) 933-2180',
      size: '$45',
      price: 'team',
      date: 'inactive',
      avatar: '',
    },
    {
      id: 37,
      address: 'Skip Hebblethwaite',
      company: 'Katz PVT LTD',
      zone: 'Sat 11:00am',
      username: 'shebblethwaite10',
      country: 'Canada',
      contact: '(610) 343-1024',
      size: '$45',
      price: 'company',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/9.png'),
    },
    {
      id: 38,
      address: 'Moritz Piccard',
      company: 'Twitternation PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'mpiccard11',
      country: 'Croatia',
      contact: '(365) 277-2986',
      size: '$45',
      price: 'enterprise',
      date: 'inactive',
      avatar: require('@/assets/images/avatars/1.png'),
    },
    {
      id: 39,
      address: 'Tyne Widmore',
      company: 'Yombu PVT LTD',
      zone: 'Tue 6:30am',
      username: 'twidmore12',
      country: 'Finland',
      contact: '(531) 731-0928',
      size: '$45',
      price: 'team',
      date: 'pending',
      avatar: '',
    },
    {
      id: 40,
      address: 'Florenza Desporte',
      company: 'Kamba PVT LTD',
      zone: 'wed 7:00 pm',
      username: 'fdesporte13',
      country: 'Ukraine',
      contact: '(312) 104-2638',
      size: '$45',
      price: 'company',
      date: 'active',
      avatar: require('@/assets/images/avatars/6.png'),
    },
    {
      id: 41,
      address: 'Edwina Baldetti',
      company: 'Dazzlesphere PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'ebaldetti14',
      country: 'Haiti',
      contact: '(315) 329-3578',
      size: '$45',
      price: 'team',
      date: 'pending',
      avatar: '',
    },
    {
      id: 42,
      address: 'Benedetto Rossiter',
      company: 'Mybuzz PVT LTD',
      zone: 'Fri 6:00am',
      username: 'brossiter15',
      country: 'Indonesia',
      contact: '(323) 175-6741',
      size: '$45',
      price: 'team',
      date: 'inactive',
      avatar: '',
    },
    {
      id: 43,
      address: 'Micaela McNirlan',
      company: 'Tambee PVT LTD',
      zone: 'Thu 9:30pm',
      username: 'mmcnirlan16',
      country: 'Indonesia',
      contact: '(242) 952-0916',
      size: '$200',
      price: 'basic',
      date: 'inactive',
      avatar: '',
    },
    {
      id: 44,
      address: 'Vladamir Koschek',
      company: 'Centimia PVT LTD',
      zone: 'wed 7:00pm',
      username: 'vkoschek17',
      country: 'Guatemala',
      contact: '(531) 758-8335',
      size: '$108',
      price: 'team',
      date: 'active',
      avatar: '',
    },
    {
      id: 45,
      address: 'Corrie Perot',
      company: 'Flipopia PVT LTD',
      zone: 'Tue 6:30am',
      username: 'cperot18',
      country: 'China',
      contact: '(659) 385-6808',
      size: '$85',
      price: 'team',
      date: 'pending',
      avatar: require('@/assets/images/avatars/3.png'),
    },
    {
      id: 46,
      address: 'Saunder Offner',
      company: 'Skalith PVT LTD',
      zone: 'Mon 10:00pm',
      username: 'soffner19',
      country: 'Poland',
      contact: '(200) 586-2264',
      size: '$60',
      price: 'enterprise',
      date: 'pending',
      avatar: '',
    },
    {
      id: 47,
      address: 'Karena Courtliff',
      company: 'Feedfire PVT LTD',
      zone: 'Sat 11:00am',
      username: 'kcourtliff1a',
      country: 'China',
      contact: '(478) 199-0020',
      size: '$25',
      price: 'basic',
      date: 'active',
      avatar: require('@/assets/images/avatars/1.png'),
    },
    {
      id: 48,
      address: 'Onfre Wind',
      company: 'Thoughtmix PVT LTD',
      zone: 'Sat 11:00am',
      username: 'owind1b',
      country: 'Ukraine',
      contact: '(344) 262-7270',
      size: '$13',
      price: 'basic',
      date: 'pending',
      avatar: '',
    },
    {
      id: 49,
      address: 'Paulie Durber',
      company: 'Babbleblab PVT LTD',
      zone: 'Tue 6:30am',
      username: 'pdurber1c',
      country: 'Sweden',
      contact: '(694) 676-1275',
      size: '$5',
      price: 'team',
      date: 'inactive',
      avatar: '',
    },
    {
      id: 50,
      address: 'Beverlie Krabbe',
      company: 'Kaymbo PVT LTD',
      zone: 'Fri 6:00am',
      username: 'bkrabbe1d',
      country: 'China',
      contact: '(397) 294-5153',
      size: '$450',
      price: 'company',
      date: 'active',
      avatar: require('@/assets/images/avatars/9.png'),
    },
  ],
}
/* eslint-enable */

// ------------------------------------------------
// GET: Return Users
// ------------------------------------------------
mock.onGet('/listings/list').reply(config => {
  // eslint-disable-next-line object-curly-newline
  const {
    q = '',
    perPage = 10,
    page = 1,
    sortBy = 'id',
    sortDesc = false,
    zone = null,
    plan = null,
    date = null,
  } = config.params
  /* eslint-enable */

  const queryLowered = q.toLowerCase()
  const filteredData = data.listings.filter(
    user =>
      /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
      (user.username.toLowerCase().includes(queryLowered) || user.address.toLowerCase().includes(queryLowered)) &&
      user.zone === (zone || user.zone) &&
      user.price === (plan || user.price) &&
      user.date === (date || user.date),
  )
  /* eslint-enable  */

  const sortedData = filteredData.sort(sortCompare(sortBy))
  if (sortDesc) sortedData.reverse()

  return [
    200,
    {
      listings: paginateArray(sortedData, perPage, page),
      total: filteredData.length,
    },
  ]
})

// ------------------------------------------------
// POST: Add new user
// ------------------------------------------------
mock.onPost('/listing/list').reply(config => {
  // Get event from post data
  const { user } = JSON.parse(config.data)

  // Assign Status
  user.date = 'active'

  const { length } = data.listings
  let lastIndex = 0
  if (length) {
    lastIndex = data.listings[length - 1].id
  }
  user.id = lastIndex + 1

  data.listings.push(user)

  return [201, { user }]
})

// ------------------------------------------------
// GET: Return Single listing
// ------------------------------------------------
mock.onGet(/\/listings\/list\/\d+/).reply(config => {
  // Get event id from URL
  let listId = config.url.substring(config.url.lastIndexOf('/') + 1)

  // Convert Id to number
  listId = Number(listId)

  const userIndex = data.listings.findIndex(e => e.id === listId)
  const user = data.listings[userIndex]

  if (user) return [200, user]
  return [404]
})
