import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";

// Routes
// eslint-disable-next-line import/no-extraneous-dependencies
import { canNavigate } from "@/libs/acl/routeProtection";
// eslint-disable-next-line import/no-extraneous-dependencies
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from "@/auth/utils";
import dashboard from "./routes/dashboard";
import pages from "./routes/pages";
import users from "./routes/users";
import plans from "./routes/plans";
import subscriptions from "./routes/subscriptions";
import invoice from "./routes/invoice";
import listings from "./routes/listings";
import features from "./routes/features";
import others from "./routes/others";
import map from "./routes/map";
import openai from "./routes/openai";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "home" } },
    ...dashboard,
    ...pages,
    ...users,
    ...plans,
    ...subscriptions,
    ...invoice,
    ...listings,
    ...others,
    ...features,
    ...map,
    ...openai,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: "auth-login" });
    // If logged in => not authorized
    return next({ name: "misc-not-authorized" });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async () => {
  // Remove initial loading
  const appLoading = document.getElementById("loaderLogo");
  if (appLoading) {
    // appLoading.style.display = 'none !important';
    appLoading.style.cssText = "display:none !important";
  }

  //Check Auth
  if (localStorage.getItem("checkAuth") == "true") {
    let API_ENDPOINT = "";
    if (window.location.hostname == "localhost") {
      API_ENDPOINT = "http://127.0.0.1:8011";
    } else {
      API_ENDPOINT = "https://api.clients.remap.ai";
    }
    // await axios.get(
    //   `${API_ENDPOINT}/auth/checkAuth`
    // );
  }
});

export default router;
