export default [
  {
    path: "/admin/copywriting",
    name: "CopyWriting",
    component: () => import("@/views/openai/CopyWriting"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
];
