export default [

  {
    path: "/admin/qldlistings",
    name: "QLDListings",
    component: () => import("@/views/listings/QLDListings"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/managelistings",
    name: "ManageListings",
    component: () => import("@/views/listings/ManageListings"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/searchandadd",
    name: "AdminSearch&Add",
    component: () => import("@/views/listings/AdminSearch&Add"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/home/listings",
    name: "Listings",
    component: () => import("@/views/listings/AllListings.vue"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/home/bcclistings",
    name: "BCCListings",
    component: () => import("@/views/listings/BCCListings"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/home/savedlistings",
    name: "SavedListings",
    component: () => import("@/views/listings/SavedListings"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/home/propertysearch",
    name: "PropertySearch",
    component: () => import("@/views/listings/PropertySearch"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/home/searchandsave",
    name: "UserSearch&Save",
    component: () => import("@/views/listings/UserSearch&Save"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/home/oppertunityfeed",
    name: "OppertunityFeed",
    component: () => import("@/views/listings/MondayFeed"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/home/stocklist",
    name: "Stocklist",
    component: () => import("@/views/listings/stocklist/Stocklist"),
    meta: {
      resource: "User",
      action: "read",
    },
  },
  {
    path: "/admin/stocklist",
    name: "StocklistAdmin",
    component: () => import("@/views/listings/stocklist/Stocklist"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/propertysearch",
    name: "PropertySearch1",
    component: () => import("@/views/listings/PropertySearch"),
    meta: {
      resource: "Admin",
      action: "read",
    },
  },
  {
    path: "/admin/managelistings",
    name: "ManageListingsTeam",
    component: () => import("@/views/listings/ManageListings"),
    meta: {
      resource: "Team Member",
      action: "read",
    },
  },
];
